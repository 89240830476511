<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.server="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.server.name }}
          </span>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type.name }}
        </template>
        <template v-slot:item.protocol="{ item }">
          {{ item.protocol.name }}
        </template>
        <template v-slot:item.frequency="{ item }">
          {{ item.frequency.name }}
        </template>
        <template v-slot:item.last_exec="{ item }">
          {{ item.last_exec ? item.last_exec : "-" }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-tooltip slot="append" right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :color="activeColor(item.active)"
                small
              >
                fas fa-circle
              </v-icon>
            </template>
            <span v-if="item.active">Active</span>
            <span v-else>Inactive</span>
          </v-tooltip>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                color="bad-2"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                    >Are you sure you want to delete this Remote backup setting?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteRemoteBackup(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                <tr
                  v-for="(expandItem, key) in expandedHeaders"
                  :key="expandItem.value"
                  class="expand-tr"
                  :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                >
                  <td
                    v-if="expandItem.value === 'server'"
                    width="150"
                    class="pl-3"
                  >
                    Server
                  </td>
                  <td v-if="expandItem.value === 'server'">
                    {{ item.server.name }}
                  </td>

                  <td
                    v-if="expandItem.value === 'type'"
                    width="150"
                    class="pl-3"
                  >
                    Type
                  </td>
                  <td v-if="expandItem.value === 'type'">
                    {{ item.type.name }}
                  </td>

                  <td
                    v-if="expandItem.value === 'protocol'"
                    width="150"
                    class="pl-3"
                  >
                    Protocol
                  </td>
                  <td v-if="expandItem.value === 'protocol'">
                    {{ item.protocol.name }}
                  </td>

                  <td
                    v-if="expandItem.value === 'frequency'"
                    width="150"
                    class="pl-3"
                  >
                    Frequency
                  </td>
                  <td v-if="expandItem.value === 'frequency'">
                    {{ item.frequency.name }}
                  </td>

                  <td
                    v-if="expandItem.value === 'active'"
                    width="150"
                    class="pl-3"
                  >
                    Active
                  </td>
                  <td v-if="expandItem.value === 'active'">
                    <v-tooltip slot="append" right>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="activeColor(item.active)"
                          small
                        >
                          fas fa-circle
                        </v-icon>
                      </template>
                      <span v-if="item.active">Active</span>
                      <span v-else>Inactive</span>
                    </v-tooltip>
                  </td>

                  <td
                    v-if="expandItem.value === 'delete'"
                    width="150"
                    class="pl-3"
                  >
                    Delete
                  </td>
                  <td v-if="expandItem.value === 'delete'">
                    <v-menu
                      v-model="item.deleteMenu"
                      :close-on-content-click="false"
                      rounded
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          icon
                          color="bad-2"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon x-small>fas fa-trash-alt</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-list width="350">
                          <v-list-item>
                            <v-list-item-content class="pb-0">
                              <v-list-item-title
                              >Are you sure you want to delete this Remote backup setting?
                              </v-list-item-title>
                              <v-list-item-subtitle class="text-right mt-2">
                                <v-btn
                                  rounded
                                  text
                                  color="tertiary"
                                  small
                                  class="text-capitalize"
                                  @click="cancelDelete(item)"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  rounded
                                  text
                                  color="bad-2"
                                  small
                                  class="text-capitalize"
                                  @click="deleteRemoteBackup(item)"
                                >
                                  Delete
                                </v-btn>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,
      options: {},

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "systemRemoteBackup";
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteRemoteBackup(item) {
      this.$emit("deleteRemoteBackup", item);
    },
    activeColor(status) {
      if (status) {
        return "#87d05a";
      } else {
        return "#e53355";
      }
    },
  },
};
</script>
